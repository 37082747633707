import { CommonStyles, SPACING } from 'base/src/ui/CommonStyles';
import { useTranslate } from 'base/src/ui/locale/locale';
import { useMoment } from 'base/src/ui/locale/moment';
import { InvoiceRepo } from 'ehawker/src/data/repositories/InvoiceRepo';
import { InvoiceSchema } from 'ehawker/src/data/schemas/invoice/InvoiceSchema';
import { usePrivilege } from 'ehawker/src/ui/query/usePrivilege';
import * as Linking from 'expo-linking';
import { View } from 'react-native';
import { Button, Card, Text } from 'react-native-paper';

interface InvoiceListItemProps {
  invoice: InvoiceSchema;
}

export function InvoiceListItem({ invoice }: InvoiceListItemProps) {
  const moment = useMoment();
  const { isSubsidized } = usePrivilege();
  const { t } = useTranslate();

  async function downloadInvoice() {
    const url = await InvoiceRepo.getInvoicePdfUrl(invoice);
    await Linking.openURL(url);
  }

  return (
    <Card
      style={{
        marginBottom: 10,
      }}
    >
      <Card.Content>
        <View
          style={[
            CommonStyles.row,
            {
              alignItems: 'center',
            },
          ]}
        >
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: SPACING.s,
            }}
          >
            <View
              style={{
                maxWidth: '90%',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  gap: SPACING.xs,
                }}
              >
                <Text
                  variant="bodyMedium"
                  numberOfLines={2}
                  style={{
                    flex: 1,
                    fontWeight: 'bold',
                  }}
                >
                  {invoice._hawkerStore + ' ' + invoice._unit}
                </Text>
              </View>
              <Text variant="bodyMedium">{`From: ${moment(invoice._fromDate).format('YYYY-MM-DD')}`}</Text>
              <Text variant="bodyMedium">{`To: ${moment(invoice._toDate).format('YYYY-MM-DD')}`}</Text>
            </View>
          </View>
          {!isSubsidized && (
            <>
              <Text
                style={{
                  fontWeight: 'bold',
                }}
                variant="bodyMedium"
              >
                ${invoice._subTotal}
              </Text>
              <Button onPress={downloadInvoice}>{t('download')}</Button>
            </>
          )}
        </View>
      </Card.Content>
    </Card>
  );
}
