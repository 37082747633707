import BottomSheet, { BottomSheetMethods } from '@devvie/bottom-sheet';
import { CommonStyles, SPACING } from 'base/src/ui/CommonStyles';
import { useTranslate } from 'base/src/ui/locale/locale';
import { Ref } from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';
import {
  Button,
  IconButton,
  Text,
  TextInput,
  useTheme,
} from 'react-native-paper';

import { ControlledDateInput } from '../../components/ControlledDateInput';

interface OrderListFilterSheetProps {
  sheetRef: Ref<BottomSheetMethods>;
  onApplyClicked: () => void;
  onResetClicked: () => void;
  onDismissClicked: () => void;
}

export function OrderListFilterSheet({
  sheetRef,
  onApplyClicked,
  onResetClicked,
  onDismissClicked,
}: OrderListFilterSheetProps) {
  const { t } = useTranslate();
  const { colors } = useTheme();

  return (
    <BottomSheet
      height={400}
      style={{
        backgroundColor: colors.background,
      }}
      ref={sheetRef}
    >
      <View
        style={[
          CommonStyles.container,
          {
            height: '100%',
            marginHorizontal: SPACING.s,
            justifyContent: 'space-between',
          },
        ]}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <IconButton icon="arrow-left" size={20} onPress={onDismissClicked} />
          <Text variant="titleMedium">{t('filter')}</Text>
          <Button onPress={onResetClicked}>{t('reset')}</Button>
        </View>
        <Controller
          name="_keyword"
          render={({ field: { onChange, value } }) => {
            return (
              <TextInput
                mode="outlined"
                label={t('keyword')}
                value={value}
                onChangeText={onChange}
              />
            );
          }}
        />
        <ControlledDateInput
          style={{
            marginTop: SPACING.s,
          }}
          name="_expectedDateFrom"
          label={t('from')}
        />
        <ControlledDateInput
          style={{
            marginTop: SPACING.s,
          }}
          name="_expectedDateTo"
          label={t('to')}
        />
        <Button
          style={{
            marginTop: SPACING.l,
          }}
          mode="contained"
          onPress={onApplyClicked}
        >
          {t('apply')}
        </Button>
      </View>
    </BottomSheet>
  );
}
