import { SPACING } from 'base/src/ui/CommonStyles';
import { useTranslate } from 'base/src/ui/locale/locale';
import { useHawkerDashboard } from 'ehawker/src/ui/query/useHawkerDashboard';
import _ from 'lodash';
import moment from 'moment';
import { View } from 'react-native';
import { Text } from 'react-native-paper';

import { UsageChart } from './UsageChart';

export function MonthlyUsageSection() {
  const { t } = useTranslate();
  const { hawkerDashboard } = useHawkerDashboard();
  const emptyData = _.range(1, 13).map((month) => ({
    x: moment()
      .month(month - 1)
      .format('MMM'),
    y: 0,
  }));

  return (
    <View>
      <Text
        variant="bodyMedium"
        style={{
          fontWeight: 'bold',
          marginVertical: 10,
        }}
      >
        {t('monthly_usage')}
      </Text>
      <UsageChart
        title={t('tray')}
        data={
          hawkerDashboard?.monthlyUsageTray.map((usage) => ({
            x: usage._month,
            y: 200,
          })) ?? emptyData
        }
      />
      <UsageChart
        containerStyle={{
          marginTop: SPACING.m,
        }}
        title={t('crockery_items')}
        data={
          hawkerDashboard?.monthlyUsageCrockery.map((usage) => ({
            x: usage._month,
            y: usage._doQty,
          })) ?? emptyData
        }
      />
    </View>
  );
}
