import { useTranslate } from 'base/src/ui/locale/locale';
import { useHawkerDashboard } from 'ehawker/src/ui/query/useHawkerDashboard';
import { usePrivilege } from 'ehawker/src/ui/query/usePrivilege';
import _ from 'lodash';
import { View } from 'react-native';
import { Card, Text } from 'react-native-paper';

export function CurrentMonthSection() {
  const { t } = useTranslate();
  const { hawkerDashboard } = useHawkerDashboard();
  const emptyCrockery =
    _.isNil(hawkerDashboard) ||
    hawkerDashboard.currentMonthUsageCrockery.length === 0;
  const emptyTray =
    _.isNil(hawkerDashboard?.currentMonthUsageTray) ||
    hawkerDashboard?.currentMonthUsageTray._tray === 0;
  const { isSubsidized } = usePrivilege();

  return (
    <>
      <Text
        variant="bodyMedium"
        style={{
          fontWeight: 'bold',
          marginVertical: 10,
        }}
      >
        {t('current_month_usage')}
      </Text>
      {emptyCrockery && emptyTray ? (
        <Text>{t('no_records_found')}</Text>
      ) : (
        <Card>
          <Card.Content>
            {hawkerDashboard?.currentMonthUsageCrockery.map((usage) => (
              <Usage
                key={JSON.stringify(usage)}
                name={usage._item}
                number={usage._doQty}
                uom={usage._uom ?? ''}
              />
            ))}
            {!emptyCrockery && <Text> </Text>}
            {!emptyTray && (
              <>
                <Usage
                  name={t('tray')}
                  number={hawkerDashboard?.currentMonthUsageTray?._tray ?? 0}
                  uom={hawkerDashboard?.currentMonthUsageTray?._uom ?? ''}
                />
                <Text> </Text>
              </>
            )}
            {!isSubsidized && (
              <Text
                variant="bodyMedium"
                style={{
                  fontWeight: 'bold',
                  alignSelf: 'flex-end',
                  marginTop: 10,
                }}
              >
                {`${t('total')}: $${hawkerDashboard?.currentMonthUsageTotal}`}
              </Text>
            )}
          </Card.Content>
        </Card>
      )}
    </>
  );
}

function Usage({
  name,
  number,
  uom,
}: {
  name: string;
  number: number;
  uom: string;
}) {
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Text variant="bodyMedium">{name}</Text>
      <Text variant="bodyMedium">
        {number} {uom}
      </Text>
    </View>
  );
}
