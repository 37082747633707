import { zodResolver } from '@hookform/resolvers/zod';
import { SPACING } from 'base/src/ui/CommonStyles';
import { useDialog } from 'base/src/ui/helpers/DialogHelper';
import { useErrorHelper } from 'base/src/ui/helpers/ErrorHelper';
import { useTranslate } from 'base/src/ui/locale/locale';
import { loggerWithTag } from 'base/src/utils/log';
import { HawkerUserRepo } from 'ehawker/src/data/repositories/HawkerUserRepo';
import {
  RegisterRequestSchema,
  RegisterRequestZodSchema,
} from 'ehawker/src/data/schemas/auth/RegisterRequestSchema';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Linking } from 'react-native';
import { Button, Text, TouchableRipple } from 'react-native-paper';

import { loginStyles } from './LoginPage';
import { UserForm } from './UserForm';
import { CONSTANTS } from '../../../utils/constants';

const log = loggerWithTag('RegisterForm.tsx');

interface RegisterFormProps {
  loginClicked: () => Promise<void>;
  afterRegister: () => void;
}

export function RegisterForm({
  loginClicked,
  afterRegister,
}: RegisterFormProps) {
  const { t } = useTranslate();
  const { errorDialog } = useErrorHelper();
  const { addDialog } = useDialog();
  const [loading, setLoading] = useState(false);
  const form = useForm<RegisterRequestSchema>({
    resolver: zodResolver(RegisterRequestZodSchema),
    defaultValues: {
      _contactCountryCode: '65',
      enrolOnly: false,
      _unit: '-',
    },
  });

  function _afterRegister() {
    log.info('After register');
    afterRegister();
    addDialog({
      message: t('register_successfully'),
    });
  }

  async function registerClicked() {
    log.info('Register button clicked');
    const isValid = await form.trigger();
    if (!isValid) return;
    try {
      setLoading(true);
      const { credentialCreateOptions } = await HawkerUserRepo.register(
        form.getValues(),
      );
      addDialog({
        message: t('prompt_biometric'),
        primaryButton: t('yes'),
        secondaryButton: t('no'),
        primaryButtonAction: async () => {
          await HawkerUserRepo.enroll({
            ...form.getValues(),
            credentialCreateOptions,
          });
          _afterRegister();
        },
        secondaryButtonAction: () => {
          _afterRegister();
        },
      });
    } catch (e) {
      errorDialog(e as Error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <FormProvider {...form}>
      <UserForm loading={loading} />
      <Button
        style={loginStyles.loginBtn}
        mode="contained"
        loading={loading}
        disabled={loading}
        onPress={registerClicked}
      >
        {t('register')}
      </Button>
      <TouchableRipple
        style={{
          marginTop: SPACING.xl,
        }}
        onPress={loginClicked}
      >
        <Text
          style={{
            textAlign: 'center',
          }}
        >
          {t('already_have_account')}
          <Button>{t('login')}</Button>
        </Text>
      </TouchableRipple>
      <Button
        style={{
          marginTop: SPACING.xl,
        }}
        labelStyle={{
          textDecorationLine: 'underline',
        }}
        onPress={async () => {
          await Linking.openURL(CONSTANTS.ORDER_TUTORIAL_URL);
        }}
      >
        {`${t('watch_tutorial')} >>`}
      </Button>
    </FormProvider>
  );
}
