import {
  PrivilegeUrl,
  PrivilegeUrlSchema,
} from 'ehawker/src/data/schemas/user/PrivilegeSchema';
import { usePrivilege } from 'ehawker/src/ui/query/usePrivilege';
import { atom, useAtom } from 'jotai';
import _ from 'lodash';

import { InvoiceList } from '../../Invoice/InvoiceList';
import { OrderList } from '../../Order/OrderList';
import { ProfilePage } from '../../Profile/ProfilePage';
import { Dashboard } from '../Dashboard';

export const DrawerItems = {
  [PrivilegeUrl.Dashboard]: {
    title: 'Dashboard',
    icon: 'home',
    component: Dashboard,
  },
  [PrivilegeUrl.Order]: {
    title: 'Order',
    icon: 'file-document-multiple-outline',
    component: OrderList,
  },
  [PrivilegeUrl.Invoice]: {
    title: 'Invoice',
    icon: 'receipt',
    component: InvoiceList,
  },
  [PrivilegeUrl.Profile]: {
    title: 'Profile',
    icon: 'account',
    component: ProfilePage,
  },
};

export const drawerItemAtom = atom<PrivilegeUrl>(PrivilegeUrl.Dashboard);
export const drawerOpenAtom = atom<boolean>(false);

export function useDrawer() {
  const { checkPrivilege } = usePrivilege();
  const [selectedDrawerItem, _selectDrawerItem] = useAtom(drawerItemAtom);
  const [drawerOpen, setDrawerOpen] = useAtom(drawerOpenAtom);
  const allowedDrawerItems = Object.keys(DrawerItems).filter((item) => {
    return checkPrivilege(PrivilegeUrlSchema.parse(item));
  });

  return {
    drawerItems: _.pick(DrawerItems, allowedDrawerItems),
    selectedDrawerItem,
    selectDrawerItem: (item: string) => {
      _selectDrawerItem(PrivilegeUrlSchema.parse(item));
    },
    drawerOpen,
    setDrawerOpen,
  };
}
