import { zodResolver } from '@hookform/resolvers/zod';
import { CustomError, CustomErrorType } from 'base/src/data/CustomError';
import { CommonStyles, SPACING } from 'base/src/ui/CommonStyles';
import { PasswordInput } from 'base/src/ui/components/input/PasswordInput';
import { useDialog } from 'base/src/ui/helpers/DialogHelper';
import { useErrorHelper } from 'base/src/ui/helpers/ErrorHelper';
import { useTranslate } from 'base/src/ui/locale/locale';
import { HawkerUserRepo } from 'ehawker/src/data/repositories/HawkerUserRepo';
import {
  RegisterRequestSchema,
  RegisterRequestZodSchema,
} from 'ehawker/src/data/schemas/auth/RegisterRequestSchema';
import { useCurrentStore } from 'ehawker/src/ui/hooks/useCurrentStore';
import { useDeviceId } from 'ehawker/src/ui/hooks/useDeviceId';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Linking, View } from 'react-native';
import { Button, IconButton, Text, useTheme } from 'react-native-paper';

import { loginStyles } from './LoginPage';
import { UserForm } from './UserForm';
import { CONSTANTS } from '../../../utils/constants';

interface LoginFormProps {
  registerClicked: () => void;
  loginClicked: () => Promise<void>;
}

export function LoginForm({ registerClicked, loginClicked }: LoginFormProps) {
  const { t } = useTranslate();
  const deviceId = useDeviceId();
  const { addDialog } = useDialog();
  const { errorDialog } = useErrorHelper();
  const [loading, setLoading] = useState(false);
  const { colors } = useTheme();
  const currentStore = useCurrentStore();
  const form = useForm<RegisterRequestSchema>({
    resolver: zodResolver(RegisterRequestZodSchema),
    defaultValues: {
      _contactCountryCode: '65',
      enrolOnly: true,
      _unit: '-',
    },
  });
  const [notYouClicked, setNotYouClicked] = useState(false);
  const showFullForm = !currentStore || notYouClicked;

  useEffect(() => {
    if (currentStore) {
      form.setValue('_hccode', currentStore._hccode ?? '');
      form.setValue('_unit', (currentStore._unit ?? '').replace('#', ''));
      form.setValue('_contactNo', (currentStore._contactNo ?? '').slice(2));
    }
  }, [currentStore]);

  async function fingerprintClicked() {
    setLoading(true);
    try {
      if (await HawkerUserRepo.isNameEnrolled(form.getValues())) {
        await loginClicked();
      } else {
        addDialog({
          message: t('prompt_biometric'),
          primaryButton: t('yes'),
          secondaryButton: t('no'),
          primaryButtonAction: async () => {
            const isValid = await form.trigger();
            if (!isValid) {
              setLoading(false);
              return;
            }
            try {
              const { credentialCreateOptions } = await HawkerUserRepo.register(
                form.getValues(),
              );
              await HawkerUserRepo.enroll({
                ...form.getValues(),
                credentialCreateOptions,
              });
              addDialog({
                message: t('enroll_success'),
              });
            } catch (e) {
              errorDialog(e as Error);
            }
          },
        });
      }
    } catch (e) {
      errorDialog(e as Error);
    }
    setLoading(false);
  }

  return (
    <FormProvider {...form}>
      {!showFullForm && (
        <>
          <View
            style={[
              CommonStyles.row,
              {
                justifyContent: 'space-between',
                alignItems: 'center',
              },
            ]}
          >
            <Text variant="titleMedium">{`${t('welcome_back')},`}</Text>
            <View style={CommonStyles.row}>
              <Button onPress={() => setNotYouClicked(true)}>
                {t('not_you')}
              </Button>
            </View>
          </View>
          <Text
            variant="titleLarge"
            style={{
              fontWeight: 'bold',
            }}
          >
            {currentStore._username}
          </Text>
          <Controller
            control={form.control}
            name="_password"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <PasswordInput
                style={loginStyles.textInput}
                mode="outlined"
                label={t('password')}
                value={value}
                inputError={error}
                onPressIn={() => form.clearErrors('_password')}
                onFocus={() => form.clearErrors('_password')}
                disabled={loading}
                onChangeText={onChange}
              />
            )}
          />
        </>
      )}
      {showFullForm && <UserForm loading={loading} />}
      <Button
        onPress={() => {
          addDialog({
            message: t('contact_admin'),
          });
        }}
      >
        {t('forgetpassword')}
      </Button>
      <View style={[CommonStyles.row]}>
        <Button
          style={[loginStyles.loginBtn, CommonStyles.container]}
          mode="contained"
          loading={loading}
          disabled={loading}
          onPress={async () => {
            setLoading(true);
            try {
              const isValid = await form.trigger();
              if (!isValid) {
                setLoading(false);
                return;
              }
              await HawkerUserRepo.loginWithPassword(form.getValues());
            } catch (e) {
              const err = e as Error;
              errorDialog(
                new CustomError(
                  CustomErrorType.RESPONSE_ERROR,
                  `${err.message} \n\n${t('device_id')}: ${deviceId}`,
                ),
              );
            }
            setLoading(false);
          }}
        >
          {t('login')}
        </Button>
        <IconButton
          style={{
            marginVertical: -6,
            marginTop: 17,
          }}
          mode="contained"
          icon="fingerprint"
          containerColor={colors.primary}
          iconColor={colors.onPrimary}
          size={30}
          onPress={fingerprintClicked}
        />
      </View>
      <Button
        style={loginStyles.loginBtn}
        mode="contained-tonal"
        loading={loading}
        disabled={loading}
        onPress={registerClicked}
      >
        {t('register_new_account')}
      </Button>
      <Button
        style={{
          marginTop: SPACING.l,
        }}
        labelStyle={{
          textDecorationLine: 'underline',
        }}
        onPress={async () => {
          await Linking.openURL(CONSTANTS.ORDER_TUTORIAL_URL);
        }}
      >
        {`${t('watch_tutorial')} >>`}
      </Button>
    </FormProvider>
  );
}
