import { Ionicons } from '@expo/vector-icons';
import { SPACING } from 'base/src/ui/CommonStyles';
import { Pressable, View } from 'react-native';
import { Menu, Text } from 'react-native-paper';

interface InvoicePickerMenuItem {
  label: string;
  value: number;
}

interface InvoicePickerMenuProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onSelected: (value: number) => void;
  selectedValue: number;
  items: InvoicePickerMenuItem[];
  label: string;
}

export function InvoicePickerMenu({
  visible,
  setVisible,
  onSelected,
  items,
  label,
  selectedValue,
}: InvoicePickerMenuProps) {
  const selectedItem = items.find((item) => item.value === selectedValue);
  const selectedLabel = selectedItem?.label ?? '';

  return (
    <Menu
      visible={visible}
      onDismiss={() => setVisible(false)}
      anchor={
        <Pressable onPress={() => setVisible(true)}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Text
              variant="titleMedium"
              style={{
                fontWeight: 'bold',
              }}
            >
              {label}
            </Text>
            <Text
              variant="titleMedium"
              style={{
                minWidth: 50,
                textAlign: 'right',
                marginRight: SPACING.s,
              }}
            >
              {selectedLabel}
            </Text>
            <Ionicons name="chevron-down" size={18} color="black" />
          </View>
        </Pressable>
      }
    >
      {items.map((item) => (
        <Menu.Item
          key={item.value}
          onPress={() => {
            onSelected(item.value);
            setVisible(false);
          }}
          title={item.label}
        />
      ))}
    </Menu>
  );
}
