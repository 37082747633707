import { useHeaderHeight } from '@react-navigation/elements';
import { CustomError, CustomErrorType } from 'base/src/data/CustomError';
import { CommonStyles } from 'base/src/ui/CommonStyles';
import { KeyboardDismissView } from 'base/src/ui/components/KeyboardDismissView';
import { PageContainer } from 'base/src/ui/components/PageContainer';
import { useErrorHelper } from 'base/src/ui/helpers/ErrorHelper';
import { useTranslate } from 'base/src/ui/locale/locale';
import { loggerWithTag } from 'base/src/utils/log';
import { HawkerUserRepo } from 'ehawker/src/data/repositories/HawkerUserRepo';
import { useCurrentStore } from 'ehawker/src/ui/hooks/useCurrentStore';
import { useDeviceId } from 'ehawker/src/ui/hooks/useDeviceId';
import Constants from 'expo-constants';
import * as SplashScreen from 'expo-splash-screen';
import { useSetAtom } from 'jotai';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Dimensions, Image as RNImage, StyleSheet, View } from 'react-native';
import { Text, useTheme } from 'react-native-paper';

import { LoginForm } from './LoginForm';
import { RegisterForm } from './RegisterForm';
import { navColorAtom } from '../../navigation/NavBar';

const log = loggerWithTag('LoginPage.tsx');

export const loginStyles = StyleSheet.create({
  outerContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginContainer: {
    width: '85%',
  },
  bottomContainer: {
    position: 'absolute',
    bottom: 0,
    alignItems: 'center',
    width: '100%',
  },
  textInput: {
    marginTop: 20,
  },
  loginBtn: {
    marginTop: 20,
  },
});

export default function LoginPage() {
  log.debug('LoginPage rendered');
  const { t } = useTranslate();
  const { errorDialog } = useErrorHelper();
  const { colors } = useTheme();
  const headerHeight = useHeaderHeight();
  const height = Dimensions.get('window').height;

  const deviceId = useDeviceId();
  const [mode, setMode] = useState<'login' | 'register'>('login');
  const currentStore = useCurrentStore();
  const hasRegisteredBefore = !_.isNil(currentStore);

  useEffect(() => {
    setMode(hasRegisteredBefore ? 'login' : 'register');
  }, [hasRegisteredBefore]);

  const backgroundColor = colors.background;
  const setNavColor = useSetAtom(navColorAtom);
  useEffect(() => {
    setNavColor(backgroundColor);
    return () => setNavColor('');
  }, [backgroundColor, setNavColor]);

  useEffect(() => {
    SplashScreen.hideAsync().catch(log.error);
  }, []);

  async function loginClicked() {
    log.info('Login button clicked');
    try {
      await HawkerUserRepo.login();
    } catch (e) {
      const err = e as Error;
      errorDialog(
        new CustomError(
          CustomErrorType.RESPONSE_ERROR,
          `${err.message} \n\n${t('device_id')}: ${deviceId}`,
        ),
      );
    }
  }

  return (
    <KeyboardDismissView>
      <PageContainer
        customStyles={{
          backgroundColor: colors.background,
        }}
      >
        <View style={[CommonStyles.container, loginStyles.outerContainer]}>
          <RNImage
            style={{
              position: 'absolute',
              height: height < 700 ? 30 : 50,
              width: '85%',
              resizeMode: 'center',
              top: 0,
            }}
            source={require('../../../../assets/common/logo.png')}
          />
          <View
            style={{ ...loginStyles.loginContainer, marginTop: -headerHeight }}
          >
            {mode === 'register' && (
              <RegisterForm
                loginClicked={async () => setMode('login')}
                afterRegister={() => setMode('login')}
              />
            )}
            {mode === 'login' && (
              <LoginForm
                loginClicked={loginClicked}
                registerClicked={() => setMode('register')}
              />
            )}
          </View>
          <View style={loginStyles.bottomContainer}>
            <Text variant="bodySmall">{t('device_id') + ': ' + deviceId}</Text>
            <Text variant="bodySmall">
              {t('version', {
                version: Constants.expoConfig?.extra?.version ?? '-',
                build: Constants.expoConfig?.extra?.build ?? '-',
              })}
            </Text>
            <Text variant="bodySmall">
              {t('copyright_line1', { year: new Date().getFullYear() }) +
                ' ' +
                t('copyright_line2')}
            </Text>
          </View>
        </View>
      </PageContainer>
    </KeyboardDismissView>
  );
}
