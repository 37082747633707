import { CommonStyles } from 'base/src/ui/CommonStyles';
import { PasswordInput } from 'base/src/ui/components/input/PasswordInput';
import { SelectTextInput } from 'base/src/ui/components/input/SelectTextInput';
import { useTranslate } from 'base/src/ui/locale/locale';
import { RegisterRequestSchema } from 'ehawker/src/data/schemas/auth/RegisterRequestSchema';
import { useCentreList } from 'ehawker/src/ui/query/useCentreList';
import { useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Dimensions, View } from 'react-native';
import { HelperText, Text, TextInput } from 'react-native-paper';

import { loginStyles } from './LoginPage';

interface UserFormProps {
  forgetPassword?: boolean;
  loading: boolean;
}

export function UserForm({ forgetPassword = false, loading }: UserFormProps) {
  const { t } = useTranslate();
  const height = Dimensions.get('window').height;
  const form = useFormContext<RegisterRequestSchema>();
  const { centreList } = useCentreList();
  const centreListInput = centreList.map((centre) => ({
    label: centre._hawkerCentre,
    value: centre._hccode,
  }));
  const unit2Ref = useRef<any>();
  const contactRef = useRef<any>();
  const [unitNext, setUnitNext] = useState(false);
  const [unit2Next, setUnit2Next] = useState(false);

  return (
    <View>
      <Controller
        control={form.control}
        name="_hccode"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <SelectTextInput
            style={{
              marginTop: height < 700 ? 60 : 0,
            }}
            disabled={loading}
            label={t('hawker_centre')}
            inputList={centreListInput}
            value={value}
            error={!!error}
            onSelected={(item) => {
              onChange(item.value);
              form.clearErrors('_hccode');
            }}
            mode="outlined"
          />
        )}
      />
      <Controller
        control={form.control}
        name="_unit"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <View
              style={[
                CommonStyles.row,
                {
                  justifyContent: 'space-between',
                  alignItems: 'center',
                },
              ]}
            >
              <TextInput
                style={[CommonStyles.container, loginStyles.textInput]}
                mode="outlined"
                maxLength={2}
                label={t('unit_no')}
                value={value.split('-')[0]}
                error={!!error}
                textContentType="username"
                autoCapitalize="none"
                onPressIn={() => form.clearErrors('_unit')}
                onFocus={() => form.clearErrors('_unit')}
                disabled={loading}
                onChangeText={(text) => {
                  if (text.length === 2 && !unitNext) {
                    unit2Ref.current.focus();
                    setUnitNext(true);
                  }
                  onChange(text + '-' + value.split('-')[1]);
                }}
                left={<TextInput.Affix text="#" />}
              />
              <Text
                variant="titleLarge"
                style={{
                  marginHorizontal: 10,
                }}
              >
                -
              </Text>
              <TextInput
                ref={unit2Ref}
                style={[CommonStyles.container, loginStyles.textInput]}
                mode="outlined"
                maxLength={2}
                value={value.split('-')[1]}
                label={' '}
                error={!!error}
                textContentType="username"
                autoCapitalize="none"
                onPressIn={() => form.clearErrors('_unit')}
                onFocus={() => form.clearErrors('_unit')}
                disabled={loading}
                onChangeText={(text) => {
                  if (text.length === 2 && !unit2Next) {
                    contactRef.current.focus();
                    setUnit2Next(true);
                  }
                  onChange(value.split('-')[0] + '-' + text);
                }}
              />
            </View>
            {!!error && (
              <HelperText type="error">{t('field_required')}</HelperText>
            )}
          </>
        )}
      />
      <Controller
        control={form.control}
        name="_contactNo"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <TextInput
              ref={contactRef}
              keyboardType="numeric"
              style={loginStyles.textInput}
              mode="outlined"
              maxLength={8}
              label={t('contact_no')}
              value={value}
              error={!!error}
              onPressIn={() => form.clearErrors('_contactNo')}
              onFocus={() => form.clearErrors('_contactNo')}
              disabled={loading}
              onChangeText={onChange}
              left={
                <TextInput.Affix
                  text={String.fromCodePoint(0x1f1f8, 0x1f1ec) + '+65'}
                />
              }
            />
            {!!error && (
              <HelperText type="error">{t('field_required')}</HelperText>
            )}
          </>
        )}
      />
      <Controller
        control={form.control}
        name="_password"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <PasswordInput
            style={loginStyles.textInput}
            mode="outlined"
            label={t('password')}
            value={value}
            inputError={error}
            onPressIn={() => form.clearErrors('_password')}
            onFocus={() => form.clearErrors('_password')}
            disabled={loading}
            onChangeText={onChange}
          />
        )}
      />
    </View>
  );
}
