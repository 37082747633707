import { useNavigation } from '@react-navigation/native';
import { SPACING } from 'base/src/ui/CommonStyles';
import { PageContainer } from 'base/src/ui/components/PageContainer';
import { PrivilegeUrl } from 'ehawker/src/data/schemas/user/PrivilegeSchema';
import { useActiveHawkerUser } from 'ehawker/src/ui/hooks/useActiveHawkerUser';
import { useHawkerUser } from 'ehawker/src/ui/hooks/useHawkerUser';
import { useLastOrder } from 'ehawker/src/ui/query/useLastOrder';
import { usePrivilege } from 'ehawker/src/ui/query/usePrivilege';
import { useEffect } from 'react';
import { ScrollView } from 'react-native';
import { FAB } from 'react-native-paper';

import { CurrentMonthSection } from './CurrentMonthSection';
import { MonthlyUsageSection } from './chart/MonthlyUsageSection';
import { PendingDeliverySection } from './pending/PendingDeliverySection';
import { NavigationProps, RouteNames } from '../../navigation/Routes';

export function Dashboard() {
  const navigation = useNavigation<NavigationProps>();
  const { privilegeLoading, checkPrivilege } = usePrivilege();
  const user = useHawkerUser();
  // prefetch
  useLastOrder(user?._hssn);
  useActiveHawkerUser();

  useEffect(() => {
    const getParamsFromHash = (hash: string) => {
      return hash
        .substring(1)
        .split('&')
        .reduce((params: any, keyValue) => {
          const [key, value] = keyValue.split('=');
          params[key] = value;
          return params;
        }, {});
    };
    const params = getParamsFromHash(window.location.hash);
    if (params.p && params.hscode) {
      navigation.navigate(params.p, { hscode: params.hscode });
    }
  }, [navigation]);

  if (privilegeLoading) {
    return (
      <PageContainer>
        <></>
      </PageContainer>
    );
  }
  return (
    <PageContainer>
      <ScrollView
        style={{
          margin: -SPACING.s,
          padding: SPACING.s,
        }}
      >
        <PendingDeliverySection />
        <CurrentMonthSection />
        <MonthlyUsageSection />
      </ScrollView>
      {checkPrivilege(PrivilegeUrl.NewOrder) && (
        <FAB
          onPress={() => navigation.navigate(RouteNames.NewOrder, {})}
          icon="plus"
          style={{
            position: 'absolute',
            margin: SPACING.l,
            right: 0,
            bottom: 0,
          }}
        />
      )}
    </PageContainer>
  );
}
