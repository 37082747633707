import { MaterialCommunityIcons } from '@expo/vector-icons';
import { SPACING } from 'base/src/ui/CommonStyles';
import { PageContainer } from 'base/src/ui/components/PageContainer';
import { useTranslate } from 'base/src/ui/locale/locale';
import { loggerWithTag } from 'base/src/utils/log';
import { HawkerUserRepo } from 'ehawker/src/data/repositories/HawkerUserRepo';
import { useHawkerUser } from 'ehawker/src/ui/hooks/useHawkerUser';
import React, { useEffect } from 'react';
import { Card, Text, useTheme } from 'react-native-paper';

import { useDashboardTitle } from '../../hooks/useDashboardTitle';

const log = loggerWithTag('ProfilePage');

export function ProfilePage() {
  log.debug('ProfilePage rendered');

  const { t } = useTranslate();
  const { colors } = useTheme();
  const user = useHawkerUser();

  useDashboardTitle(t('profile'));

  useEffect(() => {
    HawkerUserRepo.afterLogin().catch(log.error);
  }, []);

  return (
    <PageContainer
      backgroundColor={colors.elevation.level0}
      customStyles={{
        paddingTop: SPACING.s,
        alignItems: 'center',
      }}
    >
      <MaterialCommunityIcons
        name="account"
        size={100}
        color={colors.outline}
      />
      <Card
        style={{
          width: '90%',
          backgroundColor: colors.background,
        }}
      >
        <Card.Content>
          <ProfileRow label={t('owner')} value={String(user?._owner)} />
          <ProfileRow label={t('centre')} value={String(user?._hawkerCentre)} />
          <ProfileRow label={t('store')} value={String(user?._hawkerStore)} />
          <ProfileRow label={t('unit')} value={String(user?._unit)} />
          <ProfileRow
            label={t('contact_no')}
            value={String(user?._contactNo)}
          />
        </Card.Content>
      </Card>
    </PageContainer>
  );
}

function ProfileRow({ label, value }: { label: string; value: string }) {
  const { colors } = useTheme();
  return (
    <>
      <Text style={{ color: colors.outline }} variant="labelLarge">
        {label}
      </Text>
      <Text variant="bodyLarge" style={{ marginBottom: SPACING.xs }}>
        {value}
      </Text>
    </>
  );
}
