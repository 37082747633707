import { FlashList } from '@shopify/flash-list';
import { SPACING } from 'base/src/ui/CommonStyles';
import { PageContainer } from 'base/src/ui/components/PageContainer';
import { useTranslate } from 'base/src/ui/locale/locale';
import { useUser } from 'ehawker/src/ui/hooks/useUser';
import { useInvoiceList } from 'ehawker/src/ui/query/useInvoiceList';
import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { View } from 'react-native';

import { InvoiceListItem } from './InvoiceListItem';
import { InvoicePickerMenu } from './InvoicePickerMenu';
import { useDashboardTitle } from '../../hooks/useDashboardTitle';
import { useHawkerUser } from 'ehawker/src/ui/hooks/useHawkerUser';

export function InvoiceList() {
  const { t } = useTranslate();
  const user = useHawkerUser();
  const [yearMenuVisible, setYearMenuVisible] = useState(false);
  const [monthMenuVisible, setMonthMenuVisible] = useState(false);
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  const yearMenuItems = _.range(moment().year(), moment().year() - 10, -1).map(
    (year) => ({
      label: String(year),
      value: year,
    }),
  );
  const monthMenuItems = _.range(1, 13).map((month) => ({
    label: moment()
      .month(month - 1)
      .format('MMM'),
    value: month,
  }));
  const allItem = {
    label: t('all'),
    value: 0,
  };
  yearMenuItems.unshift(allItem);
  monthMenuItems.unshift(allItem);
  const { invoices, invoicesFetching, refreshInvoices, fetchMoreInvoices } =
    useInvoiceList({
      invoiceListRequest: {
        _hcsn: user?._hcsn,
        _year: year === 0 ? undefined : year,
        _month: month === 0 ? undefined : month,
      },
      disable: _.isNil(user),
    });

  useDashboardTitle(t('invoice'));

  return (
    <PageContainer
      customStyles={{
        padding: 0,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: SPACING.s,
        }}
      >
        <InvoicePickerMenu
          visible={yearMenuVisible}
          setVisible={setYearMenuVisible}
          onSelected={setYear}
          selectedValue={year}
          items={yearMenuItems}
          label={t('year')}
        />
        <InvoicePickerMenu
          visible={monthMenuVisible}
          setVisible={setMonthMenuVisible}
          onSelected={setMonth}
          selectedValue={month}
          items={monthMenuItems}
          label={t('month')}
        />
      </View>
      <FlashList
        style={{
          margin: -SPACING.s,
        }}
        contentContainerStyle={{
          padding: SPACING.s,
        }}
        estimatedItemSize={10}
        data={invoices}
        keyExtractor={(item) => String(item._invsn ?? '')}
        refreshing={invoicesFetching}
        onRefresh={refreshInvoices}
        onEndReached={fetchMoreInvoices}
        renderItem={(item) => <InvoiceListItem invoice={item.item} />}
      />
    </PageContainer>
  );
}
