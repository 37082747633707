import { useNavigation } from '@react-navigation/native';
import { SPACING } from 'base/src/ui/CommonStyles';
import { useTranslate } from 'base/src/ui/locale/locale';
import {
  OrderSchema,
  OrderStatus,
} from 'ehawker/src/data/schemas/order/OrderSchema';
import { OrderSummaryRow } from 'ehawker/src/ui/pages/Order/input/OrderSummaryRow';
import _ from 'lodash';
import React from 'react';
import { View } from 'react-native';
import { Badge, Card, MD2Colors, Text } from 'react-native-paper';
import { z } from 'zod';

import { NavigationProps, RouteNames } from '../../navigation/Routes';

export interface DoListItemProps {
  order: OrderSchema;
}

const OrderStatusColor = {
  [OrderStatus.Draft]: MD2Colors.black,
  [OrderStatus.New]: MD2Colors.red600,
  [OrderStatus.Prepared]: MD2Colors.lightBlue600,
  [OrderStatus.Delivered]: MD2Colors.green600,
  [OrderStatus.Completed]: MD2Colors.green600,
  [OrderStatus.Cancelled]: MD2Colors.grey600,
};

export function OrderListItem({ order }: DoListItemProps) {
  const navigation = useNavigation<NavigationProps>();
  const { t } = useTranslate();
  const orderItems = ((
    arr = (order._orderItems ?? '').split(',').map((s) => s.trim()),
  ) =>
    arr.length > 3 ? arr.slice(0, 3).join('\n') + '\n...' : arr.join('\n'))();

  return (
    <Card
      style={{
        marginBottom: 10,
      }}
      onPress={() => {
        if (_.isNil(order._osn)) return;
        navigation.navigate(RouteNames.OrderDetail, {
          orderSn: order._osn,
          doSn: order._dosn,
        });
      }}
    >
      <Card.Content>
        <View
          style={{
            marginBottom: SPACING.xs,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text
            variant="titleMedium"
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            {order._refNo}
          </Text>
          <Badge
            size={25}
            style={{
              paddingLeft: SPACING.xs,
              paddingRight: SPACING.xs,
              backgroundColor: order._status
                ? OrderStatusColor[
                    z.nativeEnum(OrderStatus).parse(order._status)
                  ]
                : OrderStatusColor[OrderStatus.Draft],
            }}
          >
            {t((order._status ?? '').toLowerCase())}
          </Badge>
        </View>
        <OrderSummaryRow
          label={`${t('submitted')}:`}
          value={order._submitDateStr ?? ''}
        />
        <OrderSummaryRow
          label={`${t('expected')}:`}
          value={order._expectedDateStr ?? ''}
        />
        {order._status === OrderStatus.Delivered && (
          <OrderSummaryRow
            label={`${t('delivered')}:`}
            value={order._deliveredDateStr ?? ''}
          />
        )}
        <Text variant="bodyMedium" style={{ marginTop: SPACING.xs }}>
          {orderItems}
        </Text>
      </Card.Content>
    </Card>
  );
}
